<template>
  <div class="main">
    <!-- header end-->
    <!-- body start -->
    <div class="bodybox">
      <div class="textbox">


        <div class="listbox" v-for="(item, i) in NewsList" :key="i" @click="InformationDetails(item.SysID)">
          <div class="imgbox">
            <el-image
              style="width: 100%; max-height: 100%"
              :src="item.ServeImg"
              fit="cover"
            ></el-image>
          </div>
          <div class="midbox">
            <div class="title">{{ item.Title }}</div>
            <div class="Intro">{{ item.Intro }}</div>
          </div>

          <div class="bottombox">
            <span class="adress">{{ item.CompanyName }}</span>
            <span class="time">{{ item.CreateOn }}</span>
          </div>
        </div> 
      </div>

                <div class="more" v-if="IsShowMore == 1">
            <span @click="GetPageMore()"
              >加载更多 <i class="el-icon-d-arrow-right"></i
            ></span>
          </div>
          <div class="more" v-if="IsShowMore != 1 && NewsList.length">
            <em>已加载全部 </em>
          </div>
          <div class="more" v-if="IsShowMore != 1 && !NewsList.length">
            <em>暂无数据 </em>
          </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
import timeFormat from "../util/time.js";
// 公共样式
import "../assets/css/common.css";
export default {
  data() {
    return {
      activeIndex: "5",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      NewsList: [],
      NewCompanyList: [],
      NewNewsList: [],
      CateSysID: "",
      CurCateName: "",
      CompanyTypeSysID: "",
      SaleTypeSysID: "",
      CateList: [],
      indexaa: -1,
      CompanyTypeList: [],
      indexbb: -1,
      SaleTypeList: [],
      indexcc: -1,
      PageIndex: 1,
      PageSize: 6,
      IsShowMore: 1,
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
      }
    },
  },
  methods: {
    //   企业详情
    getComInfor(id) {
      this.$router.push({ path: "/enterpriseDetails", query: { SysID: id } });
    },

    GetPageMore() {
      this.PageIndex = this.PageIndex + 1;
      this.GetNewsList();
    },

    // 获取新闻列表
    GetNewsList() {
      let that = this;
      this.$post("/api/JRServe/List", {
        State: true,
        CState:true,
        CompanyTypeSysID: this.CompanyTypeSysID,
        SaleTypeSysID: this.SaleTypeSysID,
        Sort: "CreateOn desc",
        PageSize: this.PageSize,
        PageIndex: this.PageIndex,
      })
        .then(function (data) {
          console.log(data);
          data.Obj.forEach((v) => {
            if (v.ServeImg) {
               v.ServeImg = that.urlOA + v.ServeImg.substr(1);
            }
           
            v.CreateOn = timeFormat.timeFormat(v.CreateOn, 1);
            that.NewsList.push(v);
            console.log(that.NewsList);
          });
          if (data.Obj.length == that.PageSize) {
            that.IsShowMore = 1;
          } else {
            that.IsShowMore = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    InformationDetails(SysID) {
      console.log(SysID);
      this.$router.push({
        path: "/JrDetil",
        query: { SysID: SysID },
      });
    },

    GetNewCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          State: true,
          CState:1,
          Sort: "CreateOn desc",
          PageSize: 8,
          Type: 1,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewCompanyList = data.Obj;
          that.NewCompanyList.forEach((v) => {
            v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var that = this;

    that.GetNewsList();

    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">
.textbox {
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
  overflow: hidden;
}
.listbox {
  float: left;
  width: 380px;
  margin-bottom: 20px;
  margin-right: 20px;
  // height: 320px;
  box-shadow: 0 2px 3px #ddd;
  border: 1px solid #eee;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 2px;
  .listbox:hover {
   box-shadow: 0 5px 3px #ddd;
  }
}
.imgbox {
  width: 380px;
  height: 200px;
}
.listbox:hover .imgbox::v-deep .el-image__inner{
height: 112% !important;
width: 112% !important;
margin-left: -6%;
margin-top: -6%;

}
.midbox {
  height: 130px;
  background-color: #fcfcfc;
  .title {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: 600;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .Intro {
    padding:10px;
    height: 80px;
    line-height: 32px;
    font-size: 14px;
    color: #888;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.bottombox {
  margin-top: 1px;
  height: 50px;
  background-color: #f3f7fb;
  font-size: 14px;
  color: #666;
  .adress {
    float: left;
    padding: 0 10px;
    box-sizing: border-box;
    width: 280px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 50px;
    line-height: 50px;
  }
  .time {
    float: left;
    height: 50px;
    line-height: 50px;
    font-size: 13px;
    width: 80px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 500px) {

  .textbox {
  width: 96%;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
  overflow: hidden;
}
.listbox {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 20px;
  // height: 320px;
  box-shadow: 0 2px 3px #ddd;
  border: 1px solid #eee;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 2px;
  .listbox:hover {
   box-shadow: 0 5px 3px #ddd;
  }
}
.imgbox {
  width: 100%;
  height: 200px;
}
.midbox {
  height: 130px;
  background-color: #fcfcfc;
  .title {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: 600;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .Intro {
    padding:10px;
    height: 80px;
    line-height: 32px;
    font-size: 14px;
    color: #888;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.bottombox {
  margin-top: 20px;
  height: 50px;
  background-color: #f3f7fb;
  font-size: 14px;
  color: #666;
  .adress {
    float: left;
    padding: 0 10px;
    box-sizing: border-box;
    width: 230px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 50px;
    line-height: 50px;
  }
  .time {
    float: right;
    margin-left: 10px;
    height: 50px;
    line-height: 50px;
    font-size: 13px;
    width: 80px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
}
</style>